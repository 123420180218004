<template>
	<div>
		<CRow>
			<CCol>
				<CCard>
					<CCardHeader>
						Product Form
						{{ selectedId ? "Edit " + frm.name : "Add" }}</CCardHeader
					>
					<CCardBody>
						<CRow>
							<CCol md="12">
								<CInput label="Name" v-model="frm.name" placeholder="Name" />
							</CCol>
						</CRow>
						<CRow>
							<CCol md="12">
								<div role="group" class="form-group">
									<label for="desc" class="">Description</label>
									<quill-editor
										id="desc"
										v-model="frm.description"
									></quill-editor>
								</div>
							</CCol>
							<CCol md="12">
								<div role="group" class="form-group">
									<label for="tos" class="">TOS</label>
									<quill-editor id="tos" v-model="frm.tos"></quill-editor>
								</div>
							</CCol>
						</CRow>
						<CRow>
							<CCol md="4">
								<CInput
									label="Price"
									v-model="frm.price"
									placeholder="price"
									type="number"
								/>
							</CCol>
							<CCol md="4">
								<div role="group" class="form-group">
									<label for="winway" class=""> Win Way </label>
									<select v-model="frm.winWay" id="winway" class="form-control">
										<option data-key="pengajuan" value="pengajuan">
											pengajuan
										</option>
										<option data-key="pemberi" value="pemberi">
											pemberi
										</option>
										<option data-key="undian" value="undian">
											undian
										</option>
									</select>
								</div>
							</CCol>
							<CCol md="4">
								<CInput
									label="Add By"
									v-model="frm.addName"
									placeholder="Add by"
									:readonly="true"
									type="text"
								/>
							</CCol>
						</CRow>
						<CRow>
							<CCol md="4">
								<div role="group" class="form-group">
									<label for="province" class=""> Category </label>
									<select
										v-model="frm.category"
										id="province"
										class="form-control"
									>
										<option
											v-for="(cat, i) in categories"
											:key="i"
											:data-key="cat.id"
											:value="cat.id"
										>
											{{ cat.name }}
										</option>
									</select>
								</div>
							</CCol>
							<CCol md="4">
								<CInput
									label="Expired"
									v-model="frm.expired"
									placeholder="expired"
									type="date"
								/>
							</CCol>
							<CCol md="4" v-if="guard('by_admin')">
								<div role="group" class="form-group">
									<label for="active" class=""> Active </label>
									<select v-model="frm.active" id="active" class="form-control">
										<option :value="true"> TRUE </option>
										<option :value="false"> FALSE </option>
									</select>
								</div>
							</CCol>
						</CRow>
						<CRow>
							<CCol v-for="i in [1, 2, 3, 4]" :key="i">
								<label :for="'image' + i">
									Image {{ i }} (760 X 427)
									<div class="clearfix p-1 border border-success" id="">
										<img
											height="110"
											:src="baseUrlImage(bantuan['image' + i])"
										/>
									</div>
								</label>
								<input
									@change="handleFileUpload($event, 'image' + i)"
									class="d-none"
									type="file"
									:id="'image' + i"
								/>
							</CCol>
						</CRow>
						<CRow>
							<CCol>
								<CButton
									class="float-right"
									:disabled="loading"
									@click="save"
									color="success"
								>
									SAVE
								</CButton>
							</CCol>
						</CRow>
					</CCardBody>
				</CCard>
			</CCol>
		</CRow>
	</div>
</template>

<script>
import { quillEditor } from "vue-quill-editor";
import { guard, debounce, baseUrlImage, getBase64 } from "@/plugins/utils";
import { mapState } from "vuex";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
export default {
	components: {
		quillEditor,
	},
	computed: {
		...mapState("appinfo", ["name"]),
	},
	data() {
		return {
			frm: {
				name: "",
				description: "description",
				tos: "tos",
				category: null,
				active: true,
				price: 0,
				expired: new Date(Date.now()).toISOString().split("T")[0],
				image1: "",
				image2: "",
				image3: "",
				image4: "",
				winWay: "pengajuan",
				addName: "",
			},
			guard,
			categories: [],
			baseUrlImage,
			getBase64,
			bantuan: { image1: "", image2: "", image3: "", image4: "" },
			loading: false,
			selectedId: null,
		};
	},
	mounted() {
		this.getCategories();
		if (this.$route.params.id) {
			this.selectedId = this.$route.params.id;
			this.getData();
		} else {
			this.frm.addName = this.name;
		}
	},
	methods: {
		async getData() {
			const id = this.$route.params.id;
			const { data } = await this.$http.get("product/" + id);
			const d = data.data;
			this.frm = {
				name: d.name,
				description: d.description,
				tos: d.tos,
				category: d.categoryId,
				active: d.active,
				price: d.price,
				winWay: d.winWay,
				expired: new Date(d.expired * 1000).toISOString().split("T")[0],
				image1: d.image1,
				image2: d.image2,
				image3: d.image3,
				image4: d.image4,
				addName: d.add.name,
			};
			this.bantuan = {
				image1: d.image1,
				image2: d.image2,
				image3: d.image3,
				image4: d.image4,
			};
		},
		async getCategories() {
			const { data } = await this.$http.get("category", {
				params: {
					per_page: 300,
					page: 1,
				},
			});
			this.categories = data.data.data;
		},
		handleFileUpload(e, image) {
			let vm = this;
			this.getBase64(e.target.files[0]).then(function(result) {
				//vm.form.mevo_pict_link = result
				vm.bantuan[image] = result;
			});
		},
		save: debounce(async function() {
			this.loading = true;
			for (let i = 1; i <= 4; i++) {
				this.frm["image" + i] = this.bantuan["image" + i];
			}

			try {
				let datar = {};
				this.frm.price = parseInt(this.frm.price);
				if (this.selectedId) {
					const { data } = await this.$http.put(
						"product/" + this.selectedId,
						this.frm
					);
					datar = data;
				} else {
					const { data } = await this.$http.post("product", this.frm);
					datar = data;
				}
				if (datar.status == "success") {
					this.$toast.success("Success ! " + datar.message);
					this.$router.push({ name: "Product" });
				} else {
					this.$swal("Fail !", datar.message, "error");
				}
			} catch (error) {
				this.$swal("Error !", error.message, "error");
			}
			this.loading = false;
		}, 1000),
	},
};
</script>
